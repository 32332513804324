import React from "react";

const TermsAndCondition = () => {
  return (
    <>
      <div
        className="modal review-model show"
        id="TermCondition"
        aria-hidden="false"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <div className="modal-body">
              <div className="termCondition_section">
                <div className="row justify-content-between gy-3">
                  <div className="col-lg-5">
                    <div className="condition_left">
                      <div className="Condtionleft_top">
                        <img
                          src="./images/model_close.png"
                          data-bs-dismiss="modal"
                          className="model_close"
                        />
                        <h1>Term and Condition</h1>
                      </div>
                      <div className="conditionImg">
                        <img
                          src="./images/termCondition_bg.png"
                          className="img-fluid termCondition_bg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-auto condition_middle_line">
                    <img src="./images/condition_middle_line.png" />
                  </div>
                  <div className="col-lg-6">
                    <div className="condition_right">
                      <div className="condition_right_First">
                        <h4>1. What is Lorem Ipsum?</h4>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                        </p>
                        <ul>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                        </ul>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley.
                        </p>
                      </div>
                      <div className="condition_left_First">
                        <h4>1. What is Lorem Ipsum?</h4>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                        </p>
                        <ul>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                          <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </li>
                        </ul>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
