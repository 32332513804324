import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { onCheckStatus, onOneUsersData } from "../../redux/homeAction";
import DepositModel from "../Models/DepositModel/DepositModel";
import InfoPop from "../Models/Information/InfoPop";
import { SocketContext } from "../../SocketContext/SocketContext";
import Axios from "../../redux/axios";
import DepositeAmount from "../Models/DepositModel/DepositeAmount";

const Navbar = () => {
  const dispatch = useDispatch();
  const getOneUserData = useSelector((state) => state.home.getOneUserData);
  const [infoPop, setInfoPop] = useState(false);
  const [updateUserData, setUpdateUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { socket } = useContext(SocketContext);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setInfoPop(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const userDeatils = JSON.parse(localStorage.getItem("user-details"));

  // useEffect(() => {
  //   const data = {
  //     _id: userDeatils?._id,
  //   };
  //   dispatch(onOneUsersData(data, setLoader));
  // }, [dispatch]);

  useEffect(() => {
    socket.on("take-user-data", (res) => {
      setUpdateUserData(res);
    });
  }, [socket]);

  const userLogin = localStorage.getItem("userToken");

  const config = {
    headers: {
      Authorization: `${localStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    socket.on("data-updated", function (data) {
      Axios.get("/get-all-users", config)
        .then((res) => {
          const result = res.data.users.find(
            (data) => data._id === userDeatils._id
          );
          if (result) {
            setUpdateUserData(result);
          }
        })
        .catch((err) => {});
    });
  }, []);

  // useEffect(() => {
  //   const data = {
  //     token: localStorage.getItem("userToken"),
  //     id: userDeatils?._id,
  //   };
  //   dispatch(onCheckStatus(data));
  // }, []);

  const networkProblem = localStorage.getItem("net-problem");

  const localStorageClear = () => {
    localStorage.removeItem("deposite-amount");
  };

  return (
    <>
      <section ref={wrapperRef} style={{ position: "relative" }}>
        <div className="connection-issue">
          <p className={`${networkProblem ? "active" : ""}`}>
            No internet connection
          </p>
        </div>
        <nav
          className="navbar navbar-expand-lg navbar-color"
          data-aos={"fade-down"}
        >
          <div className="container-fluid d-flex justify-content-between">
            <div className="navbar-logo">
              <Link to="/" className="navbar-brand">
                <h3>Aviator</h3>
              </Link>
            </div>
            {userLogin && (
              <>
                {loader === true ? (
                  <div
                    className="spinner-border text-secondary"
                    role="status"
                    style={{ marginRight: "50px" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="navbar-right ">
                    <div className="wallet">
                      <p>Wallet Chips</p>
                      <h6 className="text-end-center">
                        <i className="fa fa-microchip"></i>{" "}
                        {userDeatils.balance} ({userDeatils.currency})
                      </h6>
                    </div>

                    {/* <div className="deposit">
                      <button
                        className="main-btn login-btn deposit-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#AmountDeposite"
                        onClick={localStorageClear}
                      >
                        <img
                          src="./images/deposit_vector.png"
                          className="login-btn-img"
                        />
                        DEPOSIT
                      </button>
                    </div> */}
                    {/* <div className="middle_line">|</div> */}
                    {/* <div id="USER" className="user_title_content">
                      <button
                        className="main-btn login-btn user-btn"
                        onClick={() => setInfoPop(!infoPop)}
                      >
                        Menu
                        {infoPop ? (
                          <img
                            src="./images/close_vector.png"
                            className="login-btn-img"
                          />
                        ) : (
                          <img
                            src="./images/menu_bar.png"
                            className="login-btn-img"
                          />
                        )}
                      </button>
                      <i
                        className="fa fa-bars show-icon-respon"
                        onClick={() => setInfoPop(!infoPop)}
                      ></i>
                    </div> */}
                  </div>
                )}
              </>
            )}
          </div>
        </nav>
        <InfoPop infoPop={infoPop} />
        {/* <DepositModel /> */}
        {/* <DepositeAmount /> */}
      </section>
    </>
  );
};

export default Navbar;
