import React from "react";

const WithdrawRightSide = ({
  inputChangeHandler,
  value,
  submitWithdrawDetails,
  button,
}) => {
  return (
    <>
      <div className="deposit-rightt position-relative" id="withdrawd4">
        <div className="deposit-right-top d-flex justify-content-between align-items-center">
          <h4>Specify the Transaction ID</h4>
          <div className="id">ID : 11250422</div>
        </div>
        <p>For deposit please send the money to the following credentials</p>
        <div className="deposit-detail position-relative">
          <img src="./images/deposit_right.png" className="deposit_right_img" />

          <div className="form-group floating">
            <input
              type="number"
              className="form-control floating"
              id="usr"
              name="accountNumber"
              value={value.accountNumber}
              onChange={(e) => inputChangeHandler(e)}
              required
            />
            <label htmlFor="usr">Enter Your Account Number</label>
          </div>
          <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="ifscCode"
              value={value.ifscCode}
              onChange={(e) => inputChangeHandler(e)}
              required
            />
            <label htmlFor="usr">IFSC CODE</label>
          </div>
          <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="accountHolederName"
              value={value.accountHolederName}
              onChange={(e) => inputChangeHandler(e)}
              required
            />
            <label htmlFor="usr">Enter Your Account Holder Name</label>
          </div>
          <div className="form-group floating">
            <input
              type="number"
              className="form-control floating"
              id="usr"
              name="number"
              value={value.number}
              onChange={(e) => inputChangeHandler(e)}
              required
            />
            <label htmlFor="usr">Enter Your Phone Number</label>
          </div>
          <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="upiId"
              value={value.upiId}
              onChange={(e) => inputChangeHandler(e)}
              required
            />
            <label htmlFor="usr">Enter Your UPI Id</label>
          </div>
          <div className="upi_id">
            <img src="./images/upi_payment.png" />
            <img src="./images/dot_middle_line.png" />
            <h5>
              <span className="upi_text_rupay">₹</span>
              <input
                type="number"
                className="input"
                placeholder="300.00"
                name="amount"
                value={value.amount}
                onChange={(e) => inputChangeHandler(e)}
              />
            </h5>

            <div className="payment-range">
              <p>₹ 300 - ₹ 70,000 mix*</p>
            </div>
          </div>
          <div className="payment-btns">
            {/* <button className="main-btn payment-btn">₹ 1500</button>
            <button className="main-btn payment-btn">₹ 5000</button>
            <button className="main-btn payment-btn">₹ 25000</button>
            <button className="main-btn payment-btn">₹ 40000</button> */}
          </div>
          <div className="back_payment back-arrow">
            {button === true ? (
              <button className="main-btn login-btn next-page">
                <img src="./images/next_page_vector.png" />
              </button>
            ) : (
              <button
                className="main-btn login-btn next-page"
                onClick={submitWithdrawDetails}
              >
                <img src="./images/next_page_vector.png" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawRightSide;
