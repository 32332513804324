import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { onDepositeData, onWithdrawData } from "../../../redux/homeAction";
import moment from "moment";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const getDepositeData = useSelector((state) => state.home.getDepositeData);
  const getWithDrawData = useSelector((state) => state.home.getWithDrawData);
  const [showWithDraw, setShowWithDraw] = useState(0);

  const userId = JSON.parse(localStorage.getItem("user-details"));

  useEffect(() => {
    const data = { id: userId && userId._id };
    dispatch(onDepositeData(data));
    dispatch(onWithdrawData(data));
  }, []);

  return (
    <>
      <div
        className="modal review-model show"
        id="exampleModal3"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <img
              src="./images/history_model_bottom_bg.png"
              className="img-fluid model_left_img"
            />
            <hr className="model_hr" />
            <div className="modal-body">
              <div className="row justify-content-between g-3">
                <div className="col-lg-12">
                  <div className="payment_history_top">
                    <img
                      src="./images/model_close.png"
                      data-bs-dismiss="modal"
                      className="model_close"
                    />
                    <h1>Payment History</h1>
                    {/* <div className="wallet_id id">Wallet ID : #11250422</div> */}
                  </div>
                  <div className="paymentHistory">
                    <div className="payment-tabs">
                      <ul id="payment-nav">
                        <li
                          className={`payment-tab active ${
                            showWithDraw === 0 ? "active_payment_tab" : ""
                          }`}
                          onClick={() => setShowWithDraw(0)}
                        >
                          <a>Deposit History</a>
                        </li>
                        <li
                          className={`payment-tab ${
                            showWithDraw === 1 ? "active_payment_tab" : ""
                          }`}
                          onClick={() => setShowWithDraw(1)}
                        >
                          <a>withdraw History</a>
                        </li>
                      </ul>
                    </div>
                    <div className="history_detail">
                      {showWithDraw === 0 && (
                        <>
                          {getDepositeData && getDepositeData.length === 0 ? (
                            <div className="all_history">
                              <div className="no-deposit-detail">
                                <div className="no-deposit_img">
                                  <img
                                    src="./images/no_deposit.png"
                                    className="img-fluid no-deposit-img"
                                  />
                                </div>
                                <div className="no-deposit-content">
                                  <h4>No transactions</h4>
                                  <p>Make a deposit to start betting</p>
                                  <button
                                    className="main-btn login-btn deposit-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#AmountDeposite"
                                    data-bs-dismiss="modal"
                                  >
                                    <img
                                      src="./images/deposit_vector.png"
                                      className="login-btn-img"
                                    />
                                    DEPOSIT
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="deposit_history all_history">
                              {getDepositeData &&
                                getDepositeData.map((data, i) => (
                                  <div className="history_list" key={i}>
                                    <div className="row">
                                      <div className="col-8">
                                        <div className="history_list_detail">
                                          <h1>₹ {data.amount}</h1>
                                          {data.UtrNumber ? (
                                            <p>UTR Number : {data.UtrNumber}</p>
                                          ) : (
                                            <p>
                                              Transaction ID :{" "}
                                              {data.transcationId}
                                            </p>
                                          )}
                                          <p>
                                            Date & Time :
                                            {moment(data.updatedAt).format(
                                              "DD MMMM YYYY , h:mm a"
                                            )}
                                          </p>
                                          {data.status === "pending" ? (
                                            <div>
                                              <p className="status pending1">
                                                Pending
                                              </p>
                                            </div>
                                          ) : data.status === "accept" ? (
                                            <div>
                                              <p className="status accept1">
                                                Successful
                                              </p>
                                            </div>
                                          ) : data.status === "reject" ? (
                                            <div>
                                              <p className="status reject1">
                                                Reject
                                              </p>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <img src="./images/history_middle_line.png" />
                                      </div>
                                      <div className="col-3 d-flex justify-content-center">
                                        <div className="history_list_detail">
                                          <img
                                            src={`${
                                              data.showPaymentMethodImg === "0"
                                                ? "./images/hstory_upi.png"
                                                : data.showPaymentMethodImg ===
                                                  "1"
                                                ? "./images/paytm.png"
                                                : data.showPaymentMethodImg ===
                                                  "2"
                                                ? "./images/phonePay.png"
                                                : data.showPaymentMethodImg ===
                                                  "3"
                                                ? "./images/googlePay.png"
                                                : data.showPaymentMethodImg ===
                                                  "4"
                                                ? "./images/bank_icon.png"
                                                : ""
                                            }`}
                                          />

                                          {/* ./images/hstory_upi.png */}
                                          {/* ./images/paytm.png */}
                                          {/* ./images/googlePay.png */}
                                          {/* ./images/bank_icon.png */}
                                          {/* ./images/phonePay.png */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}
                      {showWithDraw === 1 && (
                        <>
                          {getWithDrawData && getWithDrawData.length === 0 ? (
                            <div className="all_history">
                              <div className="no-deposit-detail">
                                <div className="no-deposit_img">
                                  <img
                                    src="./images/no-withdraw.png"
                                    className="img-fluid no-deposit-img"
                                  />
                                </div>
                                <div className="no-deposit-content">
                                  <h4>No transactions</h4>
                                  <button
                                    className="main-btn login-btn withdraw-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal2"
                                    data-bs-dismiss="modal"
                                  >
                                    <img
                                      src="./images/deposit_vector.png"
                                      className="login-btn-img"
                                    />
                                    Withdraw
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="deposit_history all_history">
                              {getWithDrawData &&
                                getWithDrawData.map((data, i) => (
                                  <div className="history_list" key={i}>
                                    <div className="widthdraw_detail">
                                      <div className="widthdraw_title">
                                        <h1>₹ {data.amount}</h1>
                                        <p className="d-flex">
                                          {data.status === "pending" ? (
                                            <div>
                                              <p className="">Pending</p>
                                            </div>
                                          ) : data.status === "accept" ? (
                                            <div>
                                              <p className="">Successful</p>
                                            </div>
                                          ) : data.status === "reject" ? (
                                            <div>
                                              <p className="">Reject</p>
                                            </div>
                                          ) : null}
                                          :
                                          {moment(data.updatedAt).format(
                                            "DD MMMM YYYY , h:mm a"
                                          )}
                                        </p>
                                      </div>
                                      <div className="withdraw_img_vector">
                                        <img
                                          src="./images/withdraw_vector.png"
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-8">
                                        <div className="history_list_detail">
                                          <h6>To</h6>
                                          <p className="upi_id_text">
                                            UPI ID : {data.upiId}
                                          </p>
                                          <h6>Transaction ID</h6>
                                          <p>T22054678872156941269550</p>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        <img src="./images/history_middle_line.png" />
                                      </div>
                                      <div className="col-3 d-flex justify-content-center">
                                        <div className="history_list_detail">
                                          <img src="./images/hstory_upi.png" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentHistory;
