import React, { useEffect } from "react";
import Chart from "./Chart";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import Axios from "../redux/axios";
import { useState } from "react";

const Process = () => {
  const [todayGame, setTodayGame] = useState("");
  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };

  useEffect(() => {
    Axios.get("/todaygameuser", config)
      .then((res) => {
        setTodayGame(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="charts">
        <div className="featured">
          <div className="top">
            <h1 className="title">Total Revenue</h1>
            <MoreVertIcon fontSize="small" />
          </div>
          <div className="bottom">
            <div className="featuredChart">
              <CircularProgressbar value={60} text={"60%"} strokeWidth={5} />
            </div>
            <p className="title">Total Game Play today</p>
            <p className="amount">{todayGame && todayGame.Todaygameuser}</p>
            <p className="desc">
              Previous transactions processing. Last payments may not be
              included.
            </p>
            <div className="summary">
              <div className="item">
                <div className="itemTitle">Today</div>
                <div className="itemResult positive">
                  {/* <KeyboardArrowDownIcon fontSize="small" /> */}
                  <KeyboardArrowUpOutlinedIcon fontSize="small" />

                  <div className="resultAmount">
                    {todayGame && todayGame.Todaygameuser}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="itemTitle">Last Week</div>
                <div className="itemResult positive">
                  <KeyboardArrowUpOutlinedIcon fontSize="small" />
                  <div className="resultAmount">
                    {todayGame && todayGame.Weekgameuser}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="itemTitle">Last Month</div>
                <div className="itemResult positive">
                  <KeyboardArrowUpOutlinedIcon fontSize="small" />
                  <div className="resultAmount">
                    {todayGame && todayGame.month_gameuser}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Chart />
      </div>
    </>
  );
};

export default Process;
