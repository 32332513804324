import {
  ALLUSERSDATA,
  CHECKSTATUS,
  DEPOSITEDEATILS,
  ONEUSERFIND,
  ONLOGIN,
  ONMAILSUBMIT,
  ONPAYMENTNUMBER,
  ONREGISTER,
  ONSUPPORTDATA,
  PAYMENTDETAILS,
  PERSONAL_DEATIALS,
  WITHDRAWAMOUNT,
  WITHDRAWDATA,
} from "./type";

const initialState = {};
const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONLOGIN:
      return {
        ...state,
        getonLogin: action.payload,
      };
    case ONREGISTER:
      return {
        ...state,
        getonRegister: action.payload,
      };
    case PAYMENTDETAILS:
      return {
        ...state,
        getonPayment: action.payload,
      };
    case WITHDRAWAMOUNT:
      return {
        ...state,
        getonPayment: action.payload,
      };
    case PERSONAL_DEATIALS:
      return {
        ...state,
        getonPayment: action.payload,
      };
    case DEPOSITEDEATILS:
      return {
        ...state,
        getDepositeData: action.payload,
      };
    case WITHDRAWDATA:
      return {
        ...state,
        getWithDrawData: action.payload,
      };
    case ALLUSERSDATA:
      return {
        ...state,
        getUsersData: action.payload,
      };
    case ONEUSERFIND:
      return {
        ...state,
        getOneUserData: action.payload,
      };
    case ONMAILSUBMIT:
      return {
        ...state,
        getMailData: action.payload,
      };
    case ONSUPPORTDATA:
      return {
        ...state,
        getSupportData: action.payload,
      };
    case ONPAYMENTNUMBER:
      return {
        ...state,
        getPaymentNumber: action.payload,
      };
    case CHECKSTATUS:
      return {
        ...state,
        getCheckStatus: action.payload,
      };

    default:
      return state;
  }
};

export default homeReducer;
