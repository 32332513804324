import React, { useEffect } from 'react';
import Axios from "./../../redux/axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function AuthCallback() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const operatorId = queryParams.get('operatorId');
  const token = queryParams.get('token');

  useEffect(() => {
    if (operatorId && token) {
      localStorage.clear();
      // Make the backend API call using Axios or your preferred HTTP library
      Axios.post('/authenticate', {
        operatorId,
        token,
      })
      .then(response => {
        // Handle the API response as needed
        console.log('API Response:', response.data);
        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("user-details", JSON.stringify(response.data.user));
        localStorage.setItem('socketId', response.data.user.socketId);
        navigate("/");
        window.location.reload();
        window.location.href = "/";
      })
      .catch(error => {
        toast.error("Something went Wrong");
        setTimeout(()=>{
          window.close();
        },1500)
      
      });
    }
  }, [operatorId, token]);

  return (
    <div>
      Please wait...
    </div>
  );
}

export default AuthCallback;
