import {
  ALLUSERSDATA,
  CHECKSTATUS,
  DEPOSITEDEATILS,
  ONEUSERFIND,
  ONLOGIN,
  ONPAYMENTNUMBER,
  WITHDRAWDATA,
} from "./type";
import Axios from "./axios";
import { toast } from "react-toastify";

const config = {
  headers: {
    Authorization: `${localStorage.getItem("userToken")}`,
  },
};

export const onLogin = (data, navigate) => (dispatch) => {
  Axios.post(`/login`, data)
    .then((res) => {
      if (res.data.status === true) {
        localStorage.setItem("userToken", res.data.token);
        localStorage.setItem("user-details", JSON.stringify(res.data.user));
        navigate("/");
        window.location.reload();
        window.location.href = "/";
        // setTimeout(() => {
        //   toast.success(res.data.message);
        //   navigate("/");
        // }, 1000);
        dispatch({
          type: ONLOGIN,
          payload: res.data,
        });
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

export const onRegister = (data, navigate) => (dispatch) => {
  Axios.post(`/signup`, data)
    .then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message);
        // window.location.reload();
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

export const onPaymentDetails = (formData) => (dispatch) => {
  Axios.post(`/deposit`, formData, config)
    .then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

export const onCheckStatus = (data) => (dispatch) => {
  Axios.post(`/check-status`, data, config)
    .then((res) => {
      if (res.data.status === false) {
        window.location.reload();
        localStorage.clear();
      }
      dispatch({
        type: CHECKSTATUS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      // toast.error(err.response.data.message);
    });
};

export const onWithdraw = (data) => (dispatch) => {
  Axios.post(`/withdraw`, data, config)
    .then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};
export const onPersonal = (data) => (dispatch) => {
  Axios.post(`/personal`, data, config)
    .then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

export const SocialLogin = (data, navigate) => (dispatch) => {
  Axios.post(`/social_login`, data)
    .then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message);
        localStorage.setItem("userToken", res.data.token);
        localStorage.setItem("user-details", JSON.stringify(res.data.user));
        navigate("/");
        window.location.reload();
        window.location.href = "/";
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
};

export const onDepositeData = (data) => (dispatch) => {
  Axios.post(`/get-deposite`, data, config)
    .then((res) => {
      dispatch({
        type: DEPOSITEDEATILS,
        payload: res.data.deposite,
      });
    })
    .catch((err) => {});
};

export const onWithdrawData = (data) => (dispatch) => {
  Axios.post(`/get-withdraw`, data, config)
    .then((res) => {
      dispatch({
        type: WITHDRAWDATA,
        payload: res.data.withdraw,
      });
    })
    .catch((err) => {});
};

export const onAllUsersData = () => (dispatch) => {
  Axios.get(`/get-all-users`, config)
    .then((res) => {
      dispatch({
        type: ALLUSERSDATA,
        payload: res.data.users,
      });
    })
    .catch((err) => {});
};

export const onOneUsersData = (data) => (dispatch) => {
  Axios.post(`/users-details`, data, config)
    .then((res) => {
      dispatch({
        type: ONEUSERFIND,
        payload: res.data.msg,
      });
    })
    .catch((err) => {
      setTimeout(() => {
        if (
          (err &&
            err.response &&
            err.response.data &&
            err.response.data.message === "jwt expired") ||
          "invalid token" ||
          "jwt malformed"
        ) {
          localStorage.clear();
        }
      }, 2000);
    });
};

export const onMailData = (data, navigate) => (dispatch) => {
  Axios.post(`/mail`, data, config)
    .then((res) => {
      toast.success(res.data.msg);
      navigate("/login");
    })
    .catch((err) => {
      toast.error(err.response.data.msg);
    });
};

export const onSupport = (data, setNumber) => (dispatch) => {
  Axios.post(`/support-system`, data, config)
    .then((res) => {
      toast.success(res.data.message);
      setNumber("");
    })
    .catch((err) => {});
};

export const onUserImage = (formData) => (dispatch) => {
  Axios.post(`/user-image`, formData, config)
    .then((res) => {
      toast.success(res.data.message);
      setTimeout(() => {
        window.location.reload();
      }, 600);
    })
    .catch((err) => {});
};

export const onPaymentNumber = (data) => (dispatch) => {
  Axios.get(`/payment-number`, config)
    .then((res) => {
      dispatch({
        type: ONPAYMENTNUMBER,
        payload: res.data.message,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
