import React from "react";

const ChangeLanguage = () => {
  return (
    <>
      <div
        className="modal review-model show"
        id="exampleModal4"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <div className="modal-body">
              <div className="change_langauge_content">
                <div className="deposit-left">
                  <div className="deposit-left-top">
                    <img
                      src="./images/model_close.png"
                      data-bs-dismiss="modal"
                      className="model_close"
                    />
                    <h1>Change Language</h1>
                  </div>
                </div>
                <div className="language_lists">
                  <form action="#">
                    <div className="language_list">
                      <input
                        type="radio"
                        id="language1"
                        name="radio-group"
                        defaultChecked
                      />
                      <label htmlFor="language1">Hindi</label>
                      <img src="./images/india_flag.png" />
                    </div>
                    <div className="language_list">
                      <input
                        type="radio"
                        id="language2"
                        name="radio-group"
                        defaultChecked
                      />
                      <label htmlFor="language2">Hindi</label>
                      <img src="./images/america_flag.png" />
                    </div>
                    <div className="language_list">
                      <input
                        type="radio"
                        id="language3"
                        name="radio-group"
                        defaultChecked
                      />
                      <label htmlFor="language3">Hindi</label>
                      <img src="./images/india_flag.png" />
                    </div>
                    <div className="language_list">
                      <input
                        type="radio"
                        id="language4"
                        name="radio-group"
                        defaultChecked
                      />
                      <label htmlFor="language4">Hindi</label>
                      <img src="./images/india_flag.png" />
                    </div>
                    <div className="language_list">
                      <input
                        type="radio"
                        id="language5"
                        name="radio-group"
                        defaultChecked
                      />
                      <label htmlFor="language5">Hindi</label>
                      <img src="./images/india_flag.png" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeLanguage;
