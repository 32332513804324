import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { onSupport } from "../../../redux/homeAction";
import validator from "validator";

const Support = () => {
  const dispatch = useDispatch();
  const [number, setNumber] = useState("");

  const submitSupportSystem = (e) => {
    e.preventDefault();
    const data = {
      number: number,
    };
    if (!number) {
      toast.error("Please enter a mobile number");
    } else if (!validator.isMobilePhone(number)) {
      toast.error("Please enter correct number");
    } else {
      dispatch(onSupport(data, setNumber));
    }
  };

  return (
    <>
      <div
        className="modal review-model show"
        id="Support"
        aria-hidden="false"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <div className="modal-body">
              <div className="change_langauge_content">
                <div className="deposit-left">
                  <div className="deposit-left-top">
                    <img
                      src="./images/model_close.png"
                      data-bs-dismiss="modal"
                      className="model_close"
                    />
                    <h1>Support</h1>
                  </div>
                </div>
                <div className="support_section">
                  <div className="support_img">
                    <img
                      src="./images/supoort_bg.png"
                      className="img-fluid support_bg_img"
                    />
                  </div>
                  <div className="support-content">
                    <div
                      className="suppor_mail"
                      onClick={() =>
                        (window.location = "mailto:info@flyxavatior.com")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="mail_img">
                        <img src="./images/mail.png" />
                      </div>
                      <div className="support-mail-detail">
                        <h5>Email Us</h5>
                        <p>info@flyxavatior.com</p>
                      </div>
                    </div>
                    <div
                      className="suppor_mail mt-4"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="mail_img">
                        <img src="./images/mail.png" />
                      </div>
                      <div className="support-mail-detail">
                        <h5>Phone Number</h5>
                        <input
                          type="number"
                          placeholder="Enter Your Number"
                          className="support-input"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        />
                        <button
                          className="support-input-btn"
                          onClick={submitSupportSystem}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                    <div className="follow_us">
                      <img
                        src="./images/middle_vetical_line.png"
                        className="img-fluid follow_line"
                      />
                      <h5>Follow Us</h5>
                    </div>
                    <div className="support_btns">
                      <button className="main-btn Telegram_btn">
                        <img src="./images/telegra,_vecotr.png" />
                        <h5>Telegram</h5>
                      </button>
                      <button className="main-btn facebook_btn">
                        <img src="./images/facebook_vector.png" />
                        <h5>facebook</h5>
                      </button>
                      <button className="main-btn instagram_btn">
                        <img src="./images/Group 21498.svg" />
                        <h5>instagram</h5>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
