export const ONLOGIN = "ONLOGIN";

export const ONREGISTER = "ONREGISTER";

export const PAYMENTDETAILS = "PAYMENTDETAILS";

export const WITHDRAWAMOUNT = "WITHDRAWAMOUNT";

export const PERSONAL_DEATIALS = "PERSONAL_DEATIALS";

export const DEPOSITEDEATILS = "DEPOSITEDEATILS";

export const WITHDRAWDATA = "WITHDRAWDATA";

export const ALLUSERSDATA = "ALLUSERSDATA";

export const ONEUSERFIND = "ONEUSERFIND";

export const ONMAILSUBMIT = "ONMAILSUBMIT";

export const ONSUPPORTDATA = "ONSUPPORTDATA";

export const ONPAYMENTNUMBER = "ONPAYMENTNUMBER";

export const CHECKSTATUS = "CHECKSTATUS";
