import React from "react";

const HowToPlay = () => {
  return (
    <>
      <div
        className="modal review-model show"
        id="exampleModal5"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <img
              src="./images/history_model_bottom_bg.png"
              className="img-fluid model_left_img"
            />
            <div className="modal-body">
              <div className="row justify-content-between g-3">
                <div className="col-lg-12">
                  <div className="how_to_play">
                    <div className="how_to_play_name">
                      <img src="./images/howToPlay.svg" />
                      <h2>Rules of the game</h2>
                    </div>
                    <div className="how_to_play_close">
                      <img
                        src="./images/model_close.png"
                        data-bs-dismiss="modal"
                        className="model_close"
                      />
                    </div>
                  </div>
                  <div className="how_to_play_info">
                    <p>
                      FlynWin is the latest gambling entertainment that will
                      suit a new generation of players. You can win several
                      times more in just a few seconds! FlynWin is arranged on a
                      scheme that can be verified at the moment and it is
                      considered to be the only working guarantee of integrity
                      in the gambling industry.
                    </p>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="how_to_play_show">
                        <div className="how_to_play_image"></div>
                        <div className="how_to_play_details">
                          <p>
                            Enter the required amount and click the BET button
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="how_to_play_show">
                        <div className="how_to_play_image1"></div>
                        <div className="how_to_play_details">
                          <p>
                            Watch how FlynWin is planning and the coefficient is
                            growing
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <div className="how_to_play_show">
                        <div className="how_to_play_image2"></div>
                        <div className="how_to_play_details">
                          <p>
                            Withdraw before FlynWin disappears and win X times
                            more!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="how_to_play_risk">
                    <p>
                      However, do not forget that you have time limits. You need
                      to withdraw the funds before FlynWin flies away, otherwise
                      you will lose your bet. Playing FlynWin is pure
                      excitement! Here you take the risks and win. It all
                      depends on you!
                    </p>
                  </div>
                  <div className="how_to_play_rules">
                    <h2>How to play and what are the rules?</h2>
                    <ul className="how_to_play_rules_li">
                      <li>
                        To make a bet, you need to select the desired amount and
                        click the "Bet" button.
                      </li>
                      <li>
                        At the same time, there is no need to limit yourself to
                        only one bet at a time. You can place two bets using
                        both the left and right betting panel.
                      </li>
                      <li>
                        To withdraw the winnings, you need to click the
                        "Withdrawal" button. Your winnings are made up of the
                        sum-total of your bet multiplied by the cashout
                        multiplier.
                      </li>
                      <li>
                        If you do not make a Withdrawal before FlynWin flies
                        away, then the bet will be lost.
                      </li>
                    </ul>
                  </div>
                  <div className="how_to_play_rules">
                    <h2>Auto bet and Auto Withdrawal</h2>
                    <ul className="how_to_play_rules_li">
                      <li>
                        An automatic bet can be activated on the panel of any
                        bet if you tick the "Auto bet" line. In this case, bets
                        are placed automatically. Nevertheless, in order to
                        withdraw the winnings, you still need to press the
                        "Withdrawal" button for each round.
                      </li>
                      <li>
                        If you want to fully automate the game, then it is
                        possible to configure automatic withdrawal of winnings.
                        To do this, you need to activate the "Auto Withdrawal"
                        on the betting panel. Then the funds will be
                        automatically withdrawn when the coefficient you set is
                        reached.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToPlay;
