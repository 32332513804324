import React from "react";

const Loader = () => {
  return (
    <>
      <div className="loader-center">
        <img
          src="./images/loading.png"
          alt="loader"
          className="loading loadingOnProgress"
        />
      </div>
    </>
  );
};

export default Loader;
