import React from "react";
import Navbar from "../../Navbar/Navbar";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <>
      <Navbar />
      <h1 className="text-center">Page Not Found</h1>
      {/* <div className="page404_content" data-aos={"fade-up"}>
        <div className="page404_contain">
          <h1 className="upgrading_h1">We are upgrading</h1>
          <h1 className="under_h1">Under Maintenance</h1>
          <img src="./images/page404.png" className="page404_img" alt="" />
        </div>
      </div> */}
    </>
  );
};

export default PageNotFound;
