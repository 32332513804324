import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import BetDashboard from "./Component/BetGame/BetDashboard";
import Login from "./Component/Login/Login";
import Register from "./Component/Register/Register";
import { useEffect, useState } from "react";
import PersonalInformation from "./Component/Pages/PersonalInformation/PersonalInformation";
import AddressInformation from "./Component/Pages/AddressInformation/AddressInformation";
import VerifyAccountDetails from "./Component/Pages/VerifyAccountDetails/VerifyAccountDetails";
import ForgetPassword from "./Component/Pages/ForgetPassword/ForgetPassword";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { onRegister, SocialLogin } from "./redux/homeAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SocketContext, socket } from "./SocketContext/SocketContext";
import PageNotFound from "./Component/Pages/PageNotFound/PageNotFound";
import AuthCallback from "./Component/AuthCallback";
import AdminHome from "./admin/AdminHome";
import User from "./admin/User/User";
import AddUser from "./admin/User/AddUser";
import UserInformation from "./admin/User/UserInformation";
import Deposite from "./admin/deposite/Deposite";
import Withdraw from "./admin/withdraw/Withdraw";
import Games from "./admin/games/Games";
import PrivateRoute from "./PrivateRoutes/PrivateRoute";
import WithDrawDetails from "./admin/withdraw/WithDrawDetails";
import GamePlay from "./admin/GamePlay";
import DepositeDetails from "./admin/deposite/DepositeDetails";
import SupportSystem from "./admin/support/Support";
import SubAdmin from "./admin/subAdmin/SubAdmin";
import "aos/dist/aos.css";
import AOS from "aos";
import SubAdminList from "./admin/subAdmin/SubAdminList";
import Welcome from "./admin/Welcome";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import "react-internet-meter/dist/index.css";
import DummyUser from "./admin/dummyUser/DummyUser";
import PaymentNumber from "./admin/paymentNumber/PaymentNumber";

const App = () => {
  const GoolgeProvider = new GoogleAuthProvider();
  const FacebookProvider = new FacebookAuthProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [showDate, setShowDate] = useState("");
  const [wifispeed, setwifiSpeed] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);

  const openGooglePopUp = async () => {
    signInWithPopup(auth, GoolgeProvider)
      .then((result) => {
        const user = result.user.providerData;
        const details = user[0];
        const data = {
          name: details.displayName,
          email: details.email,
          phone: details.phoneNumber,
          provideId: "Google",
          uid: details.uid,
          balance: "100",
          typeData: "normal",
          colorCode: Math.floor(Math.random() * 16777215).toString(16),
        };

        dispatch(SocialLogin(data, navigate));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const openFacebookPopUp = () => {
    signInWithPopup(auth, FacebookProvider)
      .then((result) => {
        const user = result.user;
        const details = user;
        const data = {
          name: details.displayName,
          email: details.email,
          phone: details.phoneNumber,
          provideId: "facebook",
          uid: details.uid,
          balance: "100",
          typeData: "normal",
        };
        dispatch(SocialLogin(data, navigate));
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error(err.message);
        if (err.code === "auth/account-exists-with-different-credential") {
          toast.error("account exists with google");
        }
      });
  };

  const submitOnHandler = () => {
    const data = {
      email: value.email,
      password: value.password,
      mobile: value.phoneNumber,
      name: value.name,
      lastName: value.lastName,
      gender: value.type,
      dateOfBirth: showDate,
      provideId: "normal",
      langauge: value.language_Type,
      country: value.country,
      state: value.state,
      city: value.city,
      address: value.address,
      zipCode: value.zipCode,
      referralCode: value.referral,
      typeData: "normal",
      colorCode: Math.floor(Math.random() * 16777215).toString(16),
    };
    if (!value.email) {
      toast.error("Please enter email");
    } else if (!value.country) {
      toast.error("Enter a Country Name");
    } else if (!value.state) {
      toast.error("Enter a State Name");
    } else if (!value.city) {
      toast.error("Enter a City Name");
    } else {
      dispatch(onRegister(data, navigate));
    }
  };

  const inputChangeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    return () => {
      socket.off();
    };
  }, []);

  const userLogin = localStorage.getItem("userToken");

  useEffect(() => {
    if (wifispeed && parseFloat(wifispeed) < .5) {
      localStorage.setItem("net-problem", wifispeed);
    } else {
      localStorage.removeItem("net-problem");
    }
  }, [wifispeed]);

  return (
    <div className="App">
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SocketContext.Provider value={{ socket }}>
        <Routes>
          {/* <Route
            exact
            path="/admin/dashboard"
            element={
              <PrivateRoute>
                <AdminHome />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/dashboard/users"
            element={
              <PrivateRoute>
                <User />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/dashboard/add-user"
            element={
              <PrivateRoute>
                <AddUser />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/withdraw-details/:id"
            element={
              <PrivateRoute>
                <WithDrawDetails />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/deposite-details/:id"
            element={
              <PrivateRoute>
                <DepositeDetails />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/dashboard/sub-admin-list"
            element={
              <PrivateRoute>
                <SubAdminList />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/dashboard/welcome-page"
            element={
              <PrivateRoute>
                <Welcome />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/dashboard/user-details/:name"
            element={
              <PrivateRoute>
                <UserInformation />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/dashboard/payment"
            element={
              <PrivateRoute>
                <PaymentNumber />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/dashboard/user-deposit"
            element={
              <PrivateRoute>
                <Deposite />
              </PrivateRoute>
            }
          /> */}
          {/* <Route
            exact
            path="/dashboard/all-games-play"
            element={
              <PrivateRoute>
                <GamePlay />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/user-withdraw"
            element={
              <PrivateRoute>
                <Withdraw />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/all-user-games"
            element={
              <PrivateRoute>
                <Games />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/all-user-support"
            element={
              <PrivateRoute>
                <SupportSystem />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/bots"
            element={
              <PrivateRoute>
                <DummyUser />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/sub-admin"
            element={
              <PrivateRoute>
                <SubAdmin />
              </PrivateRoute>
            }
          /> */}
          {/* {userLogin ? null : (
            <Route
              exact
              path="/login"
              element={
                <Login
                  openGooglePopUp={openGooglePopUp}
                  openFacebookPopUp={openFacebookPopUp}
                />
              }
            />
          )}
          {userLogin ? null : (
            <Route
              exact
              path="/register"
              element={
                <Register
                  openGooglePopUp={openGooglePopUp}
                  openFacebookPopUp={openFacebookPopUp}
                  inputChangeHandler={inputChangeHandler}
                  value={value}
                />
              }
            />
          )} */}

          <Route exact path="/auth" element={<AuthCallback />} />

          <Route exact path="/" element={<BetDashboard />} />
          {/* {userLogin ? null : (
            <Route
              exact
              path="/personalinformation"
              element={
                <PersonalInformation
                  inputChangeHandler={inputChangeHandler}
                  value={value}
                  setShowDate={setShowDate}
                  showDate={showDate}
                />
              }
            />
          )}
          {userLogin ? null : (
            <Route
              exact
              path="/addressinformation"
              element={
                <AddressInformation
                  inputChangeHandler={inputChangeHandler}
                  value={value}
                  submitOnHandler={submitOnHandler}
                />
              }
            />
          )}
          {userLogin ? null : (
            <Route
              exact
              path="/verifyaccountdetails"
              element={<VerifyAccountDetails />}
            />
          )}
          {userLogin ? null : (
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
          )} */}

          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
        <ReactInternetSpeedMeter
          txtSubHeading={""}
          outputType=""
          customClassName={null}
          txtMainHeading=""
          pingInterval={3000} // milliseconds
          thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
          threshold={8}
          imageUrl="https://www.sammobile.com/wp-content/uploads/2019/03/keyguard_default_wallpaper_silver.png"
          downloadSize="2550420" //bytes
          callbackFunctionOnNetworkDown={(speed) => {
            if (speed < 2) {
            }
          }}
          callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(speed)}
        />
      </SocketContext.Provider>
    </div>
  );
};

export default App;
