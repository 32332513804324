import React, { useState } from "react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

const ReferAndEarn = () => {
  const userDetails = JSON.parse(localStorage.getItem("user-details"));

  const [copyNumber, setCopyNumber] = useState(
    userDetails && userDetails.referralCode
  );

  const copyCodeAndLink = () => {
    copy(copyNumber);
    toast.success(`coppied !`);
  };

  return (
    <>
      <div
        className="modal review-model show"
        id="ReferAndEarn"
        aria-hidden="false"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <div className="modal-body">
              <div className="change_langauge_content">
                <div className="deposit-left">
                  <div className="deposit-left-top">
                    <img
                      src="./images/model_close.png"
                      data-bs-dismiss="modal"
                      className="model_close"
                    />
                    <h1>Refer And Earn</h1>
                  </div>
                </div>
                <div className="refer_section">
                  <div className="text-center">
                    <img
                      src="./images/referandearn.png"
                      className="img-fluid support_bg_img"
                    />
                  </div>
                  <div className="refer-amount">
                    <h3>Refer to your friend and Get ₹50</h3>
                  </div>
                  <div className="refer-code">
                    <img
                      src="./images/refercode.png"
                      alt="referimage"
                      onClick={copyCodeAndLink}
                      style={{ cursor: "pointer" }}
                    />
                    <p>{userDetails && userDetails.referralCode}</p>
                  </div>
                  <div className="refer-code-text">
                    <p>
                      Share this code your friends and after they signup and
                      deposit the money , both of you will get Rs.50
                    </p>
                  </div>
                  <div className="refer-process">
                    <div className=" refer-process-work">
                      <img src="./images/referimage.png" />
                      <p>
                        Refer your friends for
                        <br /> signup and deposit
                      </p>
                    </div>
                    <div className=" refer-process-work">
                      <img src="./images/referamount1.png" />
                      <p>
                        your friend signup & <br /> deposit the money
                      </p>
                    </div>
                    <div className=" refer-process-work">
                      <img src="./images/referamount.png" />
                      <p>
                        Both of you will
                        <br /> get Rs.50
                      </p>
                    </div>
                  </div>
                  <div className="refer-button text-center">
                    <img
                      src="./images/referbutton.png"
                      onClick={copyCodeAndLink}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferAndEarn;
