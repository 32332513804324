import React, { useEffect, useState } from "react";
import AccountDetails from "./AccountDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  onOneUsersData,
  onPaymentDetails,
  onPaymentNumber,
} from "../../../redux/homeAction";
import { toast } from "react-toastify";
import UpiTranscation from "./PaymentOption/UpiTranscation";
import BankAccount from "./PaymentOption/BankAccount";
import PhonePeOption from "./PaymentOption/PhonePeOption";
import PaytmOption from "./PaymentOption/PaytmOption";
import validator from "validator";

const DepositModel = () => {
  const [showPayment, setShowPayment] = useState(0);
  const [value, setValue] = useState("");
  const [amount, setAmount] = useState("");
  const [button, setButton] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const dispatch = useDispatch();
  const getOneUserData = useSelector((state) => state.home.getOneUserData);
  const getPaymentNumber = useSelector((state) => state.home.getPaymentNumber);
  const userId = JSON.parse(localStorage.getItem("user-details"));

  // var match = /[a-zA-Z0-9_]{3,}@[a-zA-Z]{3,}/;

  useEffect(() => {
    dispatch(onPaymentNumber());
  }, []);

  const onChangeEventHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   const data = {
  //     _id: userId && userId._id,
  //   };
  //   dispatch(onOneUsersData(data));
  // }, []);

  const onChangeImage = async (e) => {
    const file = e.target.files[0];
    // const filesize = e.target.files[0].size / 400 / 400;

    // if (filesize > 7) {
    //   toast.error("please select image size 1 MB");
    // } else {
    //   const fileExtension = file.name.split(".").at(-1);
    //   const allowedFileTypes = ["jpg", "png"];
    //   if (!allowedFileTypes.includes(fileExtension)) {
    //     toast.error(
    //       `File does not support. Files type must be ${allowedFileTypes.join(
    //         ", "
    //       )}`
    //     );
    //     return false;
    //   }
    // }
    toast.success(`image upload successfully`);
    setImageFile(file);
  };

  const submitPaymentDetails = () => {
    const formData = new FormData();
    formData.append("amount", amount);
    formData.append("Userid", userId && userId._id);
    formData.append("transcationId", value.transcation);
    // formData.append("upiId", value.upiid);
    formData.append("mobile", value.number);
    formData.append("paymentPhoto", imageFile);
    formData.append("showPaymentMethodImg", showPayment);
    formData.append("UserBonosId", userId && userId.userReferralId);
    formData.append(
      "uniqueReferralId",
      getOneUserData && getOneUserData.uniqueReferralId
    );
    formData.append("status", "pending");

    if (!amount) {
      toast.error("Please enter amount");
    } else if (amount < 300) {
      toast.error("Please enter amount 300 more");
    } else if (!value.transcation) {
      toast.error("Please Fill transcation Id");
    } else if (value.transcation.length < 12) {
      toast.error("The transaction ID must be at least 12-18 digits");
    }
    //  else if (!value.upiid) {
    //   toast.error("Please enter a upi id");
    // } else if (!match.test(value.upiid)) {
    //   toast.error("Enter a Correct upi id ");
    // }
    else if (!value.number) {
      toast.error("Please enter a Mobile Number");
    } else if (value.number.length < 10) {
      toast.error("The Mobile Number must be at least 10 characters");
    } else if (!validator.isMobilePhone(value.number)) {
      toast.error("Please enter correct number");
    } else if (!imageFile) {
      toast.error("Please upload transaction image");
    } else {
      dispatch(onPaymentDetails(formData));
      setButton(true);
    }
  };

  useEffect(() => {
    setAmount(localStorage.getItem("deposite-amount"));
  }, [localStorage.getItem("deposite-amount")]);

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <img
              src="./images/model_left.png"
              className="img-fluid model_left_img"
            />

            <div className="modal-body">
              <div className="row justify-content-between g-3">
                <div className="col-lg-4">
                  <div className="deposit-left">
                    <div className="deposit-left-top">
                      <img
                        src="./images/model_close.png"
                        data-bs-dismiss="modal"
                        className="model_close"
                      />
                      <h1>Deposit</h1>
                    </div>
                    <div className="deposit_option">
                      <div
                        className={`deposit_options upi_deposit ${
                          showPayment === 0 ? "active" : ""
                        }`}
                        onClick={() => setShowPayment(0)}
                      >
                        <a>UPI</a>
                        <img src="./images/upi.png" className="img-fluid" />
                      </div>
                      <div
                        className={`deposit_options paytm_deposit ${
                          showPayment === 1 ? "active" : ""
                        }`}
                        onClick={() => setShowPayment(1)}
                      >
                        <a>paytm</a>
                        <img src="./images/paytm.png" className="img-fluid" />
                      </div>
                      <div
                        className={`widthraw_options deposit_options upi_deposit ${
                          showPayment === 2 ? "active" : ""
                        }`}
                        onClick={() => setShowPayment(2)}
                      >
                        <a>phonePay</a>
                        <img
                          src="./images/phonePay.png"
                          className="img-fluid"
                        />
                      </div>
                      <div
                        className={`widthraw_options deposit_options upi_deposit ${
                          showPayment === 3 ? "active" : ""
                        }`}
                        onClick={() => setShowPayment(3)}
                      >
                        <a>googlePay</a>
                        <img
                          src="./images/googlePay.png"
                          className="img-fluid"
                        />
                      </div>
                      <div
                        className={`widthraw_options deposit_options upi_deposit ${
                          showPayment === 4 ? "active" : ""
                        }`}
                        onClick={() => setShowPayment(4)}
                      >
                        <a>Bank Transfer</a>
                        <img
                          src="./images/bank_icon.png"
                          className="img-fluid"
                          style={{ width: "80px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 model_middle_line">
                  <img src="./images/Line_middle.png" />
                </div>
                <div className="col-lg-7">
                  {showPayment === 3 && (
                    <AccountDetails
                      onChangeEventHandler={onChangeEventHandler}
                      submitPaymentDetails={submitPaymentDetails}
                      onChangeImage={onChangeImage}
                      value={value}
                      imageFile={imageFile}
                      setImageFile={setImageFile}
                      button={button}
                      setAmount={setAmount}
                      amount={amount}
                      getPaymentNumber={getPaymentNumber}
                    />
                  )}
                  {showPayment === 4 && (
                    <BankAccount
                      onChangeEventHandler={onChangeEventHandler}
                      submitPaymentDetails={submitPaymentDetails}
                      onChangeImage={onChangeImage}
                      value={value}
                      imageFile={imageFile}
                      setImageFile={setImageFile}
                      button={button}
                      setAmount={setAmount}
                      amount={amount}
                      setButton={setButton}
                      getPaymentNumber={getPaymentNumber}
                      showPayment={showPayment}
                    />
                  )}
                  {showPayment === 0 && (
                    <UpiTranscation
                      onChangeEventHandler={onChangeEventHandler}
                      submitPaymentDetails={submitPaymentDetails}
                      onChangeImage={onChangeImage}
                      value={value}
                      imageFile={imageFile}
                      setImageFile={setImageFile}
                      button={button}
                      setAmount={setAmount}
                      amount={amount}
                      getPaymentNumber={getPaymentNumber}
                    />
                  )}
                  {showPayment === 2 && (
                    <PhonePeOption
                      onChangeEventHandler={onChangeEventHandler}
                      submitPaymentDetails={submitPaymentDetails}
                      onChangeImage={onChangeImage}
                      value={value}
                      imageFile={imageFile}
                      setImageFile={setImageFile}
                      button={button}
                      setAmount={setAmount}
                      amount={amount}
                      getPaymentNumber={getPaymentNumber}
                    />
                  )}
                  {showPayment === 1 && (
                    <PaytmOption
                      onChangeEventHandler={onChangeEventHandler}
                      submitPaymentDetails={submitPaymentDetails}
                      onChangeImage={onChangeImage}
                      value={value}
                      imageFile={imageFile}
                      setImageFile={setImageFile}
                      button={button}
                      setAmount={setAmount}
                      amount={amount}
                      getPaymentNumber={getPaymentNumber}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositModel;
