import React, { useState } from "react";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import PaymentHistory from "../PaymentHistory/PaymentHistory";
import Support from "../Support/Support";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";
import Withdraw from "../Withdraw/Withdraw";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageDrop from "./ImageDrop";
import HowToPlay from "../HowToPlay/HowToPlay";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";

const InfoPop = ({ infoPop }) => {
  const logOutLogin = () => {
    localStorage.clear();
    toast.success("Logout Successfully");
    setTimeout(() => {
      window.close();
    }, 1500);
  };

  const userDetails = JSON.parse(localStorage.getItem("user-details"));

  const localStorageClear = () => {
    localStorage.removeItem("deposite-amount");
  };

  return (
    <>
      <div className={`user-detail-popup ${infoPop === true ? "active" : ""}`}>
        <div className="accordion user-detail-content" id="accordionExample">
          {/* <div className="user_title">
             <ImageDrop />
            <h3 className="user_name">
              {userDetails?.balance} ({userDetails?.currency}) 
            </h3>
          </div> */}
          <div className="user_list">
            {/* <div className="user_list_item">
              <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <img src="./images/wollet_account.png" />
                    <p>Wallet Acount</p>
                  </div>
                </div>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#AmountDeposite"
                      onClick={localStorageClear}
                    >
                      <img src="./images/deposit_wallet.png" />
                      <p className="closeP">Deposit</p>
                    </div>
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                    >
                      <img src="./images/withdraw.png" />
                      <p className="closeP">Withdraw</p>
                    </div>
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal3"
                    >
                      <img src="./images/payment_history.png" />
                      <p className="closeP">Payment History</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="user_list_item"
              data-bs-toggle="modal"
              data-bs-target="#ReferAndEarn"
            >
              <img src="./images/help_information.png" />
              <p className="closeP">Refer And Earn</p>
            </div>
            <div
              className="user_list_item"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal5"
            >
              <img src="./images/help_information.png" />
              <p className="closeP">How to Play</p>
            </div>
            <div className="user_list_item">
              <div className="accordion-item">
                <div className="accordion-header" id="user_list_item2">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapes_user_list_item2"
                    aria-expanded="false"
                    aria-controls="collapes_user_list_item2"
                  >
                    <img src="./images/personal_information.png" />
                    <p>Help and Information</p>
                  </div>
                </div>
                <div
                  id="collapes_user_list_item2"
                  className="accordion-collapse collapse"
                  aria-labelledby="user_list_item2"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#Support"
                    >
                      <img src="./images/support.png" />
                      <p className="closeP">Support</p>
                    </div>
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#TermCondition"
                    >
                      <img src="./images/term_and_conditin.png" />
                      <p className="closeP">Term &amp; condition</p>
                    </div>
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#TermCondition"
                    >
                      <img src="./images/privacy_policy.png" />
                      <p className="closeP">Privacy Policy</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="user_list_item" onClick={logOutLogin}>
              <img src="./images/logout.png" />
              <p>Logout</p>
            </div>
          </div>
        </div>
      </div>
      {/* <PersonalInfo /> */}
      <Withdraw />
      <PaymentHistory />
      <ChangeLanguage />
      <Support />
      <TermsAndCondition />
      <HowToPlay />
      <ReferAndEarn />
    </>
  );
};

export default InfoPop;
